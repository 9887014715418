import React, { Component } from 'react';

// LR: Grid
import { Grid, Row, Column } from '@input-output-hk/react-grid'

// LR: Stlyes
import './ContentSectionComponent.scss';

class ContentSectionComponent extends Component {

  renderContentRight() {
    const SVGComponent = this.props.svgComponent;

    return (
      <section id={this.props.sectionId} className="section-content content--right" data-aos="fade-right">
        <Grid>
          <Row spacing={0} columnSpacing={0} sm={1} md={2} lg={2} xl={2}>
            <Column size={6} className="section-content__image">
              <div /*style={{ backgroundColor: this.props.color }}*/>
                <div>
                  <SVGComponent />
                </div>
              </div>
            </Column>
            <Column size={6} className="section-content__content">
              <div>
                {this.props.children}
              </div>
            </Column>
          </Row>
        </Grid>
      </section>
    );
  }

  renderContentLeft() {
    const SVGComponent = this.props.svgComponent;

    return (
      <section id={this.props.sectionId} className="section-content" data-aos="fade-left">
        <Grid>
          <Row spacing={0} columnSpacing={0} sm={1} md={2} lg={2} xl={2}>
            <Column className="section-content__content">
              <div>
                {this.props.children}
              </div>
            </Column>

            <Column className="section-content__image">
              <div /*style={{ backgroundColor: this.props.color }}*/>
                <div>
                  <SVGComponent />
                </div>
              </div>
            </Column>
          </Row>
        </Grid>
      </section>
    );
  }

  render() {
    const browserWidth = Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );

    let displayMode = this.props.contentRight;

    if (browserWidth < 768) {
      displayMode = false;
    }

    return displayMode ? this.renderContentRight() : this.renderContentLeft();
  }
}

export default ContentSectionComponent;
