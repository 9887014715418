import React, { Component } from 'react';

// LR: Anime JS
import anime from 'animejs';

// LR: Animate on scroll
import AOS from 'aos';

// LR: Stlyes
import './LoaderComponent.scss';

class LoaderComponent extends Component {
    componentWillMount() {
        //AOS.init();
    }

    componentDidMount() {
        let logoTimeline = anime.timeline();

        logoTimeline   
        .add({
            targets: '.g-loader',
            backgroundColor: ['rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 1)'],
            duration: 1
        })
        .add({
            targets: ['.g-loader__blocker div'],
            duration: 700,
            translateY: ['0', '-100%'],
            easing: 'easeInOutCubic',
            delay: anime.stagger(100)
        })
        .add({
            targets: '.g-loader__blocker div',
            background: 'rgba(196, 133, 71, 1)',
            duration: 0
        })
        .add({
            targets: '#logo path',
            fill: ['rgba(196, 133, 71, 0)', 'rgba(196, 133, 71, 1)'],
            transformOrigin: ['50% 50%', '50% 50%'],
            strokeWidth: [0, 0],
            scale: [1.2, 1],
            duration: 600,
            easing: 'easeInOutCubic',
            delay: anime.stagger(20)
        })
        .add({
            targets: '#loading span',
            translateY: [0, 100],
            opacity: 0,
            easing: 'easeInOutCubic',
            duration: 1000,
            delay: anime.stagger(20)
        })
        .add({
            targets: ['.g-loader__blocker div'],
            duration: 500,
            translateY: ['-100%', '0'],
            easing: 'easeInOutCubic',
            delay: anime.stagger(130)
        })
        .add({
            targets: '.g-loader',
            backgroundColor: ['white', 'rgba(255, 255, 255, 0)'],
            duration: 1
        })
        .add({
            targets: '#logo path',
            fill: 'rgba(196, 133, 71, 0)',
            duration: 1
        })
        .add({
            targets: ['.g-loader__blocker div'],
            duration: 200,
            translateY: ['0', '100%'],
            easing: 'easeInOutCubic',
            delay: anime.stagger(100)
        })
        .add({
            targets: '.g-loader',
            zIndex: -1,
            duration: 1,
            complete: (anim) => {
                AOS.init();
            }
        }) 
    }

    render() {
        return (
            <div className="g-loader">  
                <div className="g-loader__blocker">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className="g-loader__wrapper">
                    <svg id="logo" width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="top-outer" d="M217.552 196.256C216.863 196.973 216.861 198.111 217.564 198.814L228.277 209.527C228.98 210.23 230.121 210.231 230.812 209.517C250.428 189.268 262.5 161.669 262.5 131.25C262.5 69.118 212.132 18.75 150 18.75H124.927C116.97 18.75 109.34 21.9107 103.713 27.5368L96.8231 34.4272C95.6891 35.5611 96.4922 37.5 98.0958 37.5H150C201.777 37.5 243.75 79.4733 243.75 131.25C243.75 156.491 233.775 179.402 217.552 196.256Z" />
                        <path id="top-middle" d="M66.2135 65.0368C71.8396 59.4107 79.4702 56.25 87.4267 56.25H150C191.422 56.25 225 89.8286 225 131.25C225 151.313 217.122 169.537 204.291 182.995C203.605 183.715 202.463 183.713 201.76 183.01L191.048 172.298C190.345 171.595 190.347 170.457 191.027 169.732C200.468 159.671 206.25 146.136 206.25 131.25C206.25 100.184 181.066 75 150 75H60.5958C58.9922 75 58.1891 73.0611 59.3231 71.9272L66.2135 65.0368Z" />
                        <path id="top-inner" d="M28.7135 102.537C34.3396 96.9107 41.9702 93.75 49.9267 93.75H150C170.711 93.75 187.5 110.539 187.5 131.25C187.5 140.958 183.811 149.804 177.759 156.464C177.09 157.199 175.947 157.197 175.244 156.494L164.531 145.781C163.828 145.078 163.836 143.943 164.469 143.177C167.143 139.936 168.75 135.78 168.75 131.25C168.75 120.895 160.356 112.5 150 112.5H23.0958C21.4922 112.5 20.6891 110.561 21.8231 109.427L28.7135 102.537Z" />
                        <path id="bottom-inner" d="M138.02 168.75H95.55C94.5559 168.75 93.75 167.944 93.75 166.95V150V133.05C93.75 132.056 94.5559 131.25 95.55 131.25H110.7C111.694 131.25 112.5 132.056 112.5 133.05V150H143.091C143.554 150 143.999 150.178 144.334 150.498L278.002 278.148C279.176 279.27 278.383 281.25 276.759 281.25H255.355C254.887 281.25 254.437 281.068 254.101 280.741L139.274 169.259C138.938 168.932 138.488 168.75 138.02 168.75Z" />
                        <path id="bottom-middle" d="M56.25 204.45V187.5V133.05C56.25 132.056 57.0559 131.25 58.05 131.25H73.2C74.1941 131.25 75 132.056 75 133.05V187.5H130.703C131.174 187.5 131.626 187.684 131.962 188.013L224.096 278.163C225.249 279.292 224.45 281.25 222.837 281.25H201.358C200.888 281.25 200.437 281.066 200.101 280.738L124.274 206.762C123.938 206.434 123.487 206.25 123.017 206.25H58.05C57.0559 206.25 56.25 205.444 56.25 204.45Z" />
                        <path id="bottom-outer" d="M108.39 243.75H20.55C19.5559 243.75 18.75 242.944 18.75 241.95V225V133.05C18.75 132.056 19.5559 131.25 20.55 131.25H35.7C36.6941 131.25 37.5 132.056 37.5 133.05V225H115.89C116.361 225 116.813 225.185 117.15 225.515L170.853 278.165C172.004 279.293 171.205 281.25 169.593 281.25H148.11C147.639 281.25 147.187 281.065 146.85 280.735L109.65 244.265C109.313 243.935 108.861 243.75 108.39 243.75Z" />
                    </svg>
                </div>
            </div>
        );
    }
}

export default LoaderComponent;
