import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

// LR: Redux
import { Provider as ReduxProvider } from 'react-redux';
import configureStore, { history } from './redux/configureStore';

// LR: Router
import { Route, Switch } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'

// LR: Grid Provider
import { Provider as GridProvider } from '@input-output-hk/react-grid'

// LR: Theme
import './scss/theme.scss';

// LR: Routes
import HomeRoute from './routes/home/HomeRoute';
//import Http404Route from './routes/http/404/Http404Route';

// LR: Store
const store = configureStore(/* intial state... */)


class App extends React.Component {
    render() {
        return (
            <ReduxProvider store={ store }>
                <ConnectedRouter history={history}>        
                    <GridProvider>    
                        <Switch>
                            <Route path="/" component={HomeRoute} />
                            {/* <Route component={Http404Route} /> */}
                        </Switch>
                    </GridProvider>  
                </ConnectedRouter>
            </ReduxProvider>
        );
    }
}


// LR: We'll put react router here
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
