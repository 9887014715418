import React, { Component } from 'react';

// LR: Grid
import { Grid, Row, Column } from '@input-output-hk/react-grid'

// LR: Stlyes
import './CTASectionComponent.scss';

class CTASectionComponent extends Component {
  render() {
    return (
      <section id="contact" className="section-cta" data-aos="fade-up" data-aos-delay="100">
        <Grid>
          <Row spacing={0} columnSpacing={0}>
            <Column>
              <div>
                <h2 className="underline">Together, we can make great things!</h2>
                <p data-aos="fade-up" data-aos-delay="300">You should get in touch no matter who you are even if it’s for a coffee <span role="img" aria-label="Cool">😎</span> You can catch me on this <a href="mailto:web@liamriddell.co.uk?subject=Introduction" className="underline">email</a>. If you require CV, please get in contact.</p>
                {/*<a href="/static/Liam%20Riddell%20CV19%20Lite.pdf" className="btn-primary" data-aos="fade-up" data-aos-delay="500">Download Resume</a>*/}
              </div>
            </Column>
          </Row>
        </Grid>
      </section>
    );
  }
}

export default CTASectionComponent;
