import React, { Component } from 'react';

// LR: Layout
import DefaultLayout from '../../layout/default/DefaultLayout';

// LR: Sections
import IntroductionComponent from '../../components/introduction/IntroductionComponent';
import ContentSectionComponent from '../../components/content-section/ContentSectionComponent';
import CTASectionComponent from '../../components/cta-section/CTASectionComponent';

// LR: Svgs
import { ReactComponent as DevelopmentSVG } from '../../static/development.svg';
import { ReactComponent as DesignSVG } from '../../static/design.svg';
import { ReactComponent as HackerSVG } from '../../static/hacker.svg';

import './HomeRoute.scss';
import { ImageScroller, ImageScrollerItem } from '../../components/image-scroller/ImageScrollerComponent';

class HomeRoute extends Component {
  render() {
    return (
      <div className="HomeRoute">
        <DefaultLayout>
          <IntroductionComponent />

          <ContentSectionComponent sectionId="security" svgComponent={HackerSVG} color="#6efe87">
            <h2 className="underline underline--green">Cyber Security</h2>
            <p>Not content with accepting that something works, I've always been fascinated with breaking things down into their raw components to understand their interdependencies. Whether physical or virtual, I still have a desire to know how and why.</p>
          </ContentSectionComponent>

          <ContentSectionComponent sectionId="development" svgComponent={DevelopmentSVG} color="#FECB4A" contentRight={true}>
            <h2 className="underline">Developer</h2>
            <p>Building tech that performs as well as it looks. Continually striving for perfection while pushing the boundaries of what's possible to create groundbreaking solutions. Surrounding yourself with like-minded people will always bring the best out of everyone and thing.</p>
          </ContentSectionComponent>

          <ContentSectionComponent sectionId="design" svgComponent={DesignSVG} color="#4D4AFE">
            <h2 className="underline underline--purple">Designer</h2>
            <p>I have hands-on experience in design and development communities which has given me a unique edge to interweave both elements into all my projects. The ability to express your creativity within a multitude of outlets will always provide an individual's unique advantage.</p>
          </ContentSectionComponent>

          <CTASectionComponent />

          <section>
            <h2 className="text--title" data-aos="fade-up">Collaborated with the <strong>world’s best</strong> workplaces</h2>
            <ImageScroller width="100%">
              <ImageScrollerItem src="/static/workplaces/ao-logo.png" height="108px" />
              <ImageScrollerItem src="/static/workplaces/sony-playstation.png" height="54px" />
              <ImageScrollerItem src="/static/workplaces/mattress-firm-logo.png" width="124px" />
              <ImageScrollerItem src="/static/workplaces/fifa-logo.png" width="124px" />
              <ImageScrollerItem src="/static/workplaces/itn-logo.png" width="96px" />
              <ImageScrollerItem src="/static/workplaces/skybet.png" height="38px" />
              <ImageScrollerItem src="/static/workplaces/nhs-logo.png" width="124px" />
              <ImageScrollerItem src="/static/workplaces/ab-logo.png" width="124px" />

              <ImageScrollerItem src="/static/workplaces/ao-logo.png" height="108px" />
              <ImageScrollerItem src="/static/workplaces/sony-playstation.png" height="54px" />
              <ImageScrollerItem src="/static/workplaces/mattress-firm-logo.png" width="124px" />
              <ImageScrollerItem src="/static/workplaces/fifa-logo.png" width="124px" />
              <ImageScrollerItem src="/static/workplaces/itn-logo.png" width="96px" />
              <ImageScrollerItem src="/static/workplaces/skybet.png" height="38px" />
              <ImageScrollerItem src="/static/workplaces/nhs-logo.png" width="124px" />
              <ImageScrollerItem src="/static/workplaces/ab-logo.png" width="124px" />
            </ImageScroller>
          </section>
        </DefaultLayout>
      </div>
    );
  }
}

export default HomeRoute;
