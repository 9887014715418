// LR: Redux Imports
import { createStore, compose, applyMiddleware } from 'redux';
import createRootReducer from './reducers/reducers'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

// LR: Router
import { routerMiddleware } from 'connected-react-router'

// LR: Browser History
import { createBrowserHistory } from 'history'

// LR: Create history object.
export const history = createBrowserHistory()

// LR: Export configureStore helper
export default function configureStore(preloadedState) { 
    const store = createStore(
      createRootReducer(history), // root reducer with router state
      preloadedState,
      composeWithDevTools(
        applyMiddleware(
          routerMiddleware(history), // for dispatching history actions
        )
      ),
    )
  
    return store
}