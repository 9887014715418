import React, { Component } from 'react';

// LR: Grid
import { Grid, Row, Column } from '@input-output-hk/react-grid'

// LR: SVG
import { ReactComponent as PersonalLogo } from '../../static/LR19x42.svg';


// LR: Stlyes
import './FooterComponent.scss';

class FooterComponent extends Component {
    render() {
        return (
            <footer className="g-footer">
                <Grid>
                    <Row spacing={0} columnSpacing={0} xs={1} >
                        <Column verticalAlign="center">
                            <Row spacing={0} columnSpacing={0} >
                                <Column size={0}>
                                    <PersonalLogo />
                                </Column>
                                <Column className="g-footer__copyright" verticalAlign="center">
                                    Liam Riddell &copy; {new Date().getFullYear()}
                                </Column>
                            </Row>
                        </Column>
                        <Column className="g-footer__nav" verticalAlign="center">
                            <nav className="nav__desktop">
                                <a href="#security">Cyber Security</a>
                                <a href="#development">Development</a>
                                <a href="#design">Design</a>
                            </nav>
                        </Column>
                    </Row>
                </Grid>
            </footer>
        );
    }
}

export default FooterComponent;
