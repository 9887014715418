// LR: Redux
import { combineReducers } from 'redux';

// LR: Routing
import { connectRouter } from 'connected-react-router';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history)
  // LR: New Reducers here...
});

export default createRootReducer;