import React, { Component } from 'react';

// LR: Grid
import { Grid, Row, Column } from '@input-output-hk/react-grid'

// LR: Logo
import { ReactComponent as PersonalLogo } from '../../static/LR19x42.svg';

// LR: Stlyes
import './HeaderComponent.scss';

class HeaderComponent extends Component {
  render() {
    return (
      <header className="g-header">
        <Grid>
          <Row spacing={0} columnSpacing={0}>
            <Column size={0}>
              <PersonalLogo data-aos="fade-down" />
            </Column>

            <Column className="g-header__nav" verticalAlign="center">
              <nav className="nav__desktop" data-aos="fade-down" data-aos-delay="100">
                <a href="#security" className="hidden-tablet-down">Cyber Security</a>
                <a href="#development" className="hidden-tablet-down">Development</a>
                <a href="#design" className="hidden-tablet-down">Design</a>
                <a href="#contact" className="underline">Get in touch</a>
              </nav>
            </Column>
          </Row>
        </Grid>
      </header>
    );
  }
}

export default HeaderComponent;
