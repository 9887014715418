import React, { Component } from 'react';

// LR: Stlyes
import './ImageScrollerComponent.scss';

export class ImageScrollerItem extends Component {
  render() {
    return <img src={this.props.src} className={this.props.auto ? this.props.auto === "width" ? "auto-w" : "auto-h" : ""} width={this.props.width} height={this.props.height} alt={this.props.alt} />
  }
}

export class ImageScroller extends Component {
  render() {


    return (
      //data-aos="fade-up" data-aos-delay="300"
      <section className="slider" style={{ width: this.props.width, height: this.props.height }} data-aos="fade-up" data-aos-delay="100">
        <div className="slide__track">
          {this.props.children.map((child, index) => <div className="slider__slide " auto={this.props.auto} data-aos="fade-up" data-aos-delay={100 * index}>{child}</div>)}
        </div>
      </section>
    );
  }
}