import React, { Component } from 'react';

// LR: Grid
import { Grid, Row, Column } from '@input-output-hk/react-grid'

// LR: Logo
import { ReactComponent as DribbbleNetwork } from '../../static/dribbble.svg'; 
import { ReactComponent as InstagramNetwork } from '../../static/instagram.svg'; 
import { ReactComponent as GithubNetwork } from '../../static/github.svg'; 
import { ReactComponent as TwitterNetwork } from '../../static/twitter.svg'; 
import { ReactComponent as LinkedInNetwork } from '../../static/linkedin.svg'; 
import { ReactComponent as StackOverflowNetwork } from '../../static/stackoverflow.svg'; 

// LR: Stlyes
import './SocialComponent.scss';

// LR: Networks
const socialNetworks = [
    {
        name: "Dribbble",
        url: "https://dribbble.com/Riddell",
        iconSvgComponent: DribbbleNetwork
    },
    {
        name: "Stack Overflow",
        url: "https://stackoverflow.com/users/5308388/riddell?tab=profile",
        iconSvgComponent: StackOverflowNetwork
    },   
    {
        name: "Github",
        url: "https://github.com/Riddeller",
        iconSvgComponent: GithubNetwork
    },    
    {
        name: "Instagram",
        url: "https://www.instagram.com/riddell.design",
        iconSvgComponent: InstagramNetwork
    },
    {
        name: "Twitter",
        url: "https://twitter.com/liamcriddell",
        iconSvgComponent: TwitterNetwork
    },
    {
        name: "LinkedIn",
        url: "https://www.linkedin.com/in/liam-riddell/",
        iconSvgComponent: LinkedInNetwork
    }
]

class SocialComponent extends Component {
  render() {
    return (
      <aside className="g-social">
        <Grid>
            <Row spacing={0} columnSpacing={0} xs={1} sm={1} md={1} lg={1}>
                {
                    socialNetworks.map( (network, i) => {
                        const NeworkIcon = network.iconSvgComponent;
                        
                        return (
                            <Column key={i}>
                                <div data-aos="zoom-in-left" data-aos-delay={(i+1) * 200}>
                                    <a href={network.url} className="g-social__network" title={network.name} target="_blank" rel="noopener noreferrer">
                                        <NeworkIcon title={network.name} />
                                    </a>
                                </div>
                            </Column>
                        )
                    })
                }
            </Row>
        </Grid>
      </aside>
    );
  }
}

export default SocialComponent;
