import React, { Component } from 'react';

// LR: Grid
import { Grid, Row, Column } from '@input-output-hk/react-grid'

// LR: Stlyes
import './IntroductionComponent.scss';

class IntroductionComponent extends Component {
  render() {
    return (
      <section id="about" className="introduction">
        <Grid>
          <Row spacing={0} columnSpacing={0} c>
            <Column size={12} className="introduction__container">
              <div className="introduction__center">
                <div className="center__content">
                  <h1 data-aos="fade-up" data-aos-delay="100">Liam Riddell</h1>
                  <p data-aos="fade-up" data-aos-delay="300">Beautiful design. Bleeding-edge tech.</p>
                  <p data-aos="fade-up" data-aos-delay="500">
                    Welcome <span role="img" aria-label="Waving">👋</span>, As a developer who advocates innovation. I've gained a wealth of experience in my career building apps, websites, software and artificial intelligence.
                            Find me on <a href="https://github.com/LiamRiddell" className="underline">GitHub</a> or <a href="https://stackoverflow.com/users/5308388/riddell?tab=profile" className="underline">Stack Overflow</a>. Check out my latest app <a href="https://www.producthunt.com/posts/notemaster-beta" className="underline">NoteMaster</a>.
                        </p>
                  <p className="text-muted" data-aos="fade-up" data-aos-delay="700">Lead Developer at <a href="https://www.enee.io/" className="underline">Enee.io</a></p>
                </div>

                <div className="center__scroller" data-aos="fade-right" data-aos-delay="1000">
                  <div className="scroller__hint">SCROLL DOWN</div>
                  <div className="scroller__track">
                    <div className="scroller__thumb"></div>
                  </div>
                </div>
              </div>
            </Column>
          </Row>
        </Grid>
      </section>
    );
  }
}

export default IntroductionComponent;
