import React, { Component } from 'react';

// LR: Components
import HeaderComponent from '../../components/header/HeaderComponent';
import FooterComponent from '../../components/footer/FooterComponent';
import SocialComponent from '../../components/social/SocialComponent';
import LoaderComponent from '../../components/loader/LoaderComponent';


class DefaultLayout extends Component {
    render() {
        return (
            <div className="g-container">
                <LoaderComponent />
                <HeaderComponent />
                <SocialComponent />
                <main>
                    {this.props.children}
                </main>
                <FooterComponent />
            </div>
        )
    }
}

export default DefaultLayout;